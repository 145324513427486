.widget {
    background-color: #fff;
    font-size: 13px;
    margin-bottom: 30px;
    height: 100%;
    &.fullwidth {
        max-width: 100%;
    }
    .widget-heading {
        background: #2098CA;
        color: #fff;
        padding: 11px 20px;

        h2 {
            font-size: 17px;
            margin: 0;
        }
        a {
            color: inherit;
        }
    }
    .widget-body {
        padding: 20px;
        overflow-y: auto;
    }
    ul {
        padding-left: 0px;
        list-style: none;
        li {
            margin-bottom: 15px;
            padding-left: 55px;
            padding-top: 1px;
            position: relative;
            font-size: 16px;
            &:before {
                content: "\f04d";
                font-family: 'FontAwesome';
                color: #cacaca;
                font-size: 25px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    .date {
        color: @gray;
        margin-top: 15px;
        font-size: 12px;
    }
}
.widget-simple {
    display: block;
    position: relative;
    background: #A2D7E9;
    color: #fff;
    text-decoration: none !important;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    &:hover, &:focus {
        color: #fff;
        background: @btn-info-bg;
    }
}
.widget-simple-title {
    display: block;
    padding: 15px 20px 5px;
    font-size: 16px;
}
.widget-simple-count {
    display: block;
    padding: 0 20px;
    font-size: 30px;
    small {
        font-size: 15px;
    }
}
.widget-simple-icon {
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 50px;
    line-height: 92px;
}
