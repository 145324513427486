@font-face {
  font-family: 'w2online';
  src: url('../fonts/w2online.eot?40850267');
  src: url('../fonts/w2online.eot?40850267#iefix') format('embedded-opentype'),
       url('../fonts/w2online.woff?40850267') format('woff'),
       url('../fonts/w2online.ttf?40850267') format('truetype'),
       url('../fonts/w2online.svg?40850267#w2online') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "w2online";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-mail-alt:before { content: '\e801'; } /* '' */
.icon-search:before { content: '\e802'; } /* '' */
.icon-users:before { content: '\e803'; } /* '' */
.icon-user:before { content: '\e804'; } /* '' */
.icon-home:before { content: '\e805'; } /* '' */
.icon-doc-text:before { content: '\e806'; } /* '' */
.icon-briefcase:before { content: '\e807'; } /* '' */
.icon-bell-alt:before { content: '\e808'; } /* '' */
.icon-users-1:before { content: '\e809'; } /* '' */
.icon-newspaper:before { content: '\e80a'; } /* '' */
.icon-basket:before { content: '\e80b'; } /* '' */
.icon-enwox:before { content: '\e80c'; } /* '' */
