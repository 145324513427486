/* Colors */
/* Scaffolding */
/* Typography */
h1, .h1 {
    margin-top: 0;
    margin-bottom: 20px;
}
h2, .h2 {
    margin-top: 25px;
    margin-bottom: 20px;
}
h3, .h3 {
    margin-top: 25px;
    margin-bottom: 20px;
}
/* Iconography */
/* Components */
/* Tables */
.table {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: inherit;
            }
        }
    }
}
/* Buttons */
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
}
.btn-action {
    width: 28px;
    line-height: 26px;
    font-size: 12px;
    padding: 0;
    text-align: center;
}
.btn-right {
    float: right;
    margin-left: 7px;
}
.btn-danger {
    &:hover, &:focus {
        background-color: @gray;
        color: #fff;
        border-color: @gray;
    }
}
.btn-red {
    background-color: @brand-blue;
    color: #fff;
    border-color: @brand-blue;
    &:hover, &:focus {
        background-color: @gray;
        color: #fff;
        border-color: @gray;
    }
}
.btn-gray {
    background-color: #bbb;
    color: #fff;
    border-color: #bbb;
    &:hover, &:focus {
        background-color: @gray;
        color: #fff;
        border-color: @gray;
    }
}
/* Forms */
form {
    ul.error {
        list-style: none;
        margin: 0;
        padding: 0;
        > li {
            .alert();
            .alert-danger();
        }
    }
}
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
.col-sm-2-10 {
    float: left;
    position: relative;
    width: 100%;
}
@media(min-width: 768px) {
    .col-sm-2-10 {
        width: 20%;
    }
}
/* Container sizes */
/* Navbar */
.navbar-default {
    border: none;
    font-size: 13px;
    .navbar-nav>li>a {
        padding-left: 30px;
        padding-right: 30px;
        background: @gray;
        text-transform: uppercase;
        border-left: 1px solid @gray-light;
        i {
            margin-right: 8px;
        }
    }
    .navbar-nav>li>a.inverse {
        background: @brand-blue;
        color: #fff;
        &:hover, &:focus {
            color: @gray;
            background: @gray-light;
        }
        &.active {
            color: @gray;
            background: @gray-lighter;
        }
    }
}
/* Navs */
.tab-content {
    padding-top: 30px;
}
.nav-tabs {
    > li {
        > a {
            color: @text-color;
            padding: 10px 60px;
            margin-right: 0;
            border-radius: 4px 4px 0 0;
            &:hover, &:focus {
                background-color: @gray-lighter;
                color: @gray;
            }
        }
        &.active > a {
            color: @gray;
        }
    }
}
.nav-pills {
    > li {
        > a {
            padding: 15px 60px;
            border-radius: 0;
            color: #87888A;
            background: #ddd;
            &:hover, &:focus {
                background: #cbcbcb;
            }
        }
        &.active > a {
            color: #fff;
            background: #39C;
            &:hover, &:focus {
                background: #2c84af;
            }
        }
    }
}
.nav-pills > li + li {
    margin-left: 0;
    border-left: 1px solid #cbcbcb;
}
.nav-pills.nav-stacked {
    > li {
        > a {
            padding: 15px;
        }
    }
    > li + li {
        border-left: none;
    }
}
/* Tabs */
/* Pills */
/* Pagination */
.pagination {
    margin: 0;
}
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Tooltips */
.tooltip {
    font-family: @font-family-sans-serif;
    font-size: 13px;
    &.in {
        filter: alpha(opacity=90);
        opacity: .9;
    }
}
.tooltip-inner {
    border-radius: 2px;
    padding: 10px 15px;
}
/* Popovers */
/* Labels */
.label {
    border-radius: 0;
}
.table .label {
    font-size: inherit;
}
/* Modals */
.modal.fullScreen {
    top: 60px;
    .modal-dialog {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        .modal-content {
            height: 100%;
            overflow-y: auto;
        }
    }
}
.modal-dialog {
    margin-top: 60px;
}
@media (min-width: @screen-md-min) {
    .modal-dialog {
        margin-top: 17.1%;
    }
    .modal.fullScreen {
        .modal-dialog {
            width: 750px;
        }
    }
}
/* Alerts */
.alert {
    padding: 14px;
}
.alert-dismissable, .alert-dismissible {
    padding-right: 35px;
}
/* Progress bars */
/* List group */
.btn-row > * {
    margin-right: 15px;
}
/* Panels */
.panel-heading {
    border: none;
}
.panel-info {
    border-color: @brand-info;
}
.panel-info>.panel-heading {
    color: #fff;
    background: @brand-info;
    border-color: @brand-info;
}
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
.breadcrumb {
    background-color: transparent;
    font-size: 12px;
    margin-bottom: 30px;
    > li > a {
        color: @brand-blue;
    }
}
/* Carousel */
/* Close */
/* Code */
/* Type */
.page-first {
    margin-top: 0;
}
.dl {
    width: 100%;
    margin-bottom: 20px;
    .dt, .dd {
        padding: 4px 8px;
        vertical-align: text-top;
    }
    .dt {
        text-align: right;
    }
}
@media (min-width: 768px) {
    .dl-horizontal dt {
        white-space: normal;
        padding-bottom: 6px;
        line-height: 18px;
    }
}
