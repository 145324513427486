.list-products {
    .list-group;
    .row;

    margin-bottom: -30px;

    > div {
        margin-bottom: 30px
    }

    .list-group-item {
        padding: 30px 15px;
    }

    h4 {
        height: 20px;
        overflow-y: hidden;
    }

    p {
        height: 40px;
        overflow-y: hidden;
        margin: 0;
        padding: 0;
    }
}

// mobile and tablets
@media (max-width: @screen-sm-max) {
    .list-products {
        margin-bottom: -5px;

        > div {
            margin-bottom: 5px
        }
        
        .list-group-item {
            padding: 10px;
        }
        
        p {
            height: 20px;
        }
    }
}
