.point-hero {
    text-align: center;
    font-size: 28px;
    margin: 0 0 20px;
}
.point-hero h3 {
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 700;
}
.point-hero small {
    font-size: 60%;
    font-weight: 700;
    color: inherit;
}
.point-hero desc {
    display: block;
    font-size: 12px;
}
