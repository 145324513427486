.maintenance-content {
    .login-content;
    box-shadow: 6px 4px 9px 0px #444;

    .maintenance-heading {
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
    }

    .maintenance-message {
        line-height: 25px;
        padding: 30px;
    }

    .maintenance-greeting, .maintenance-closing {
        display: block;
    }

    .maintenance-greeting {
        padding-bottom: 10px;
    }

    .maintenance-closing {
        padding: 30px 0;
    }
}

