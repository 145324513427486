.view-switcher {
    .row();
    
    background: #fff;
    margin: 0;
    border-bottom: 30px solid #f6f6f6;

    > div {
        position: relative;
        min-height: 1px;
        float: left;
        padding: 0;
        margin: 0;
    }

    .overview {
        width: 40%;
        margin-right: -30px;
        border-right: 30px solid #f6f6f6;
        .block {
            margin: 0;
        }
    }

    .detail {
        width: 60%;
        margin-right: -30px;
        border-left: 30px solid #f6f6f6;
        .block {
            margin: 0;
        }
    }
}

@media (max-width: @screen-md-max) {
    .view-switcher {
        border-bottom-width: 10px;

        .overview {
            width: 100%;
            border-right: none;
            border-bottom: 10px solid #f6f6f6;
        }
        .detail {
            width: 100%;
            border-left: none;
        }
    }
}
