@mobile-padding: 10px;

// mobile
.padded {
    padding: @mobile-padding;
}
.text-desktop {
    display: none;
}

// mobile and tablets
@media (max-width: @screen-sm-max) {
    .hidden-mobile {
        display: none;
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin: 0;
        margin-bottom: @mobile-padding;
    }
    .col-xs-auto, .col-sm-auto {
        width: auto;
    }
    .alert {
        margin-bottom: @mobile-padding;
    }
    .block {
        padding: @mobile-padding;
        margin-bottom: @mobile-padding;
    }
    .divided {
        margin-bottom: @mobile-padding;
    }
    .nav-pills, .nav-pills.nav-stacked {
        > li {
            > a {
                padding: 7px 14px;
            }
        }
    }
    .widget {
        padding: @mobile-padding;
        margin-bottom: @mobile-padding;
        h2, .h2 {
            padding-bottom: @mobile-padding / 2;
            margin-bottom: @mobile-padding / 2;
        }
        ul, ul li:last-child {
            margin-bottom: 0;
        }
        ul li {
            margin-bottom: 5px;
        }
    }
}

// notebook and desktop
@media (min-width: @screen-md-min) {
    .visible-mobile {
        display: none;
    }
    .padded {
        padding: 10px;
    }
    .text-desktop {
        display: initial;
    }
}

// desktop
@media (min-width: @screen-lg-min) {
    .visible-mobile {
        display: none;
    }
    .padded {
        padding: 15px;
    }
    .text-desktop {
        display: initial;
    }
}
