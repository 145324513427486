.ca-table {
    position: relative;
    margin: -10px auto;
    width: 410px;
    .table {
        margin-bottom: 0;
    }
    .table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
        position: relative;
        /*width: 48px;*/
        /*height: 36px;*/
        text-align: center;
        border: none;
        padding: 8px 16px;
        line-height: 20px;
        font-weight: 300;
    }
    .ca-week, .ca-week-this {
        > l {
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background: @gray-light;
        }
    }
    .ca-week-this {
        color: @btn-primary-bg;
    }
    .ca-month-previous {
        color: @gray-light;
    }
    .ca-day-event {
        color: @btn-primary-bg;
        font-size: 14px;
        font-weight: 700 !important;
    }
    .ca-today {
        font-size: 14px;
        color: #fff;
        background: @btn-primary-bg;
    }
    .ca-head-today {
        color: @btn-primary-bg;
    }
    .fa-circle {
        color: #337ab7;
        position: absolute;
        left: 15px;
        bottom: 2px;
    }
}
.ca-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 5;
}
