@wizard-item-height: 50px;
@wizard-item-font-size: floor(@wizard-item-height / 3.12);
@wizard-item-margin: 3px;
@wizard-item-color: #87888A;
@wizard-item-bg: #DDD;
@wizard-item-hover-darken: 7%;
@wizard-item-active-color: #fff;
@wizard-item-active-bg: #3399CC;
@wizard-item-done-color: #fff;
@wizard-item-done-bg: #B5CE56;
@wizard-badge-size: 21px;
@wizard-badge-font-size: @wizard-item-font-size;

.wizard-steps {
    .nav;
    .nav-justified;
    margin-bottom: 30px;
    > li {
        > a {
            position: relative;
            margin: 0;
            margin-right: @wizard-item-margin;
            height: @wizard-item-height;
            padding: 0 ceil(@wizard-item-height / 2);
            color: @wizard-item-color;
            background: @wizard-item-bg;
            text-align: center;
            font-size: @wizard-item-font-size;
            line-height: @wizard-item-height;
            text-decoration: none;
            &:before {
                content: "";
                border-top: (@wizard-item-height / 2) solid transparent;
                border-bottom: (@wizard-item-height / 2) solid transparent;
                border-left: ceil(@wizard-item-height / 3) solid #f6f6f6;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:after {
                content: "";  
                border-top: ceil(@wizard-item-height / 2) solid transparent;
                border-bottom: ceil(@wizard-item-height / 2) solid transparent;
                border-left: ceil(@wizard-item-height / 3) solid @wizard-item-bg;
                position: absolute;
                right: ceil(@wizard-item-height / 3) * -1;
                top: 0;
                z-index: 1;
            }
            &:hover, &:focus {
                background: darken(@wizard-item-bg, @wizard-item-hover-darken);
                &:after {
                    border-left-color: darken(@wizard-item-bg, @wizard-item-hover-darken);
                }
            }
            .badge {
                color: #fff;
                background: @wizard-item-color;
                border: 1px solid @wizard-item-color;
                border-radius: 100%;
                padding: 0;
                margin-top: -2px;
                text-align: center;
                font-size: @wizard-badge-font-size;
                height: @wizard-badge-size;
                width: @wizard-badge-size;
                line-height: @wizard-badge-size - 2;
            }
        }
    }
    > li.active {
        > a {
            color: @wizard-item-active-color;
            background: @wizard-item-active-bg;
            &:after {
                border-left-color: @wizard-item-active-bg;
            }
            &:hover, &:focus {
                background: darken(@wizard-item-active-bg, @wizard-item-hover-darken);
                &:after {
                    border-left-color: darken(@wizard-item-active-bg, @wizard-item-hover-darken);
                }
            }
            .badge {
                color: @wizard-item-active-color;
                background: transparent;
                border-color: @wizard-item-active-color;
            }
        }
    }
    > li.done {
        > a {
            color: @wizard-item-done-color;
            background: @wizard-item-done-bg;
            &:after {
                border-left-color: @wizard-item-done-bg;
            }
            &:hover, &:focus {
                background: darken(@wizard-item-done-bg, @wizard-item-hover-darken);
                &:after {
                    border-left-color: darken(@wizard-item-done-bg, @wizard-item-hover-darken);
                }
            }
            .badge {
                color: @wizard-item-done-color;
                background: transparent;
                border-color: @wizard-item-done-color;
            }
        }
    }
    > li:first-child {
        > a {
            &:before {
                display: none;
            }
        }
    }
    > li:last-child {
        > a {
            margin-right: 0;
            &:after {
                display: none;
            }
        }
    }
}

// mobile and tablets
@media (max-width: @screen-sm-max) {
    .wizard-steps {
        margin-bottom: 8px;

        > li {
            > a {
                font-size: 15px;
                height: 40px;
                line-height: 40px;
                margin: 0;
                margin-bottom: 2px;

                &:before, &:after {
                    display: none;
                }
            }
        }
    }
}
