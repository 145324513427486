.btn-row {
    .row;
    margin-left: 0;
    margin-right: 0;
    > div {
        padding: 0;
    }
}

// notebook and desktop
@media (min-width: @screen-md-min) {
    .btn-row {
        > div {
            width: auto;
        }
    }
}
