.tracy-close {
    position: absolute;
    top: -2px;
    right: -2px;
    padding: 7px !important;
    cursor: pointer;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    border-left: 1px solid rgba(0,0,0,0.1) !important;
}

#tracy-debug-bar {
    max-height: 84px;
    overflow-y: auto;
}
