.signature-pad {
    position: relative;

    div.well {
        position: absolute;
        width: 500px;
        height: 250px;
        background: #fff;
        padding: 0;
        margin: 10px auto;
        text-align: center;
    }

    canvas {
        width: 500px;
        height: 250px;
    }

    .clear-pad {
        height: 280px;
    }
}
