.read td a, .read td {
    font-weight: normal;
    color: inherit;
}
.unread td a, .unread td {
    font-weight: 700;
    color: inherit;
}
.unread td {
    background-color: #f9f8f8;
}

.table-mail {
    margin: 0;
}
table.table-mail tr td {
    padding: 12px;
}
.table-mail .mail-date {
    padding-right: 20px;
}
