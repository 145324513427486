#nprogress {
    .bar {
        position: fixed;
        background: #fff;
        height: 5px;
        z-index: 2000;
    }
    .peg {
        box-shadow: 0 0 10px #fff, 0 0 5px #fff;
    }
    .spinner {
        display: none;
    }
}

.ui-autocomplete {
    z-index: 1055;
}

/* Gridstack */
.grid-stack {
    margin: 0 -10px;
}
.grid-nav {
    position: relative;
    height: 45px;
    margin-top: -45px;
}
.grid-nav-left {
    position: absolute;
    left: 0;
}
.grid-nav-right {
    position: absolute;
    right: 0;
}
.grid-nav form {
    display: inline-block;
}
.grid-stack>.grid-stack-item>.grid-stack-item-content {
    overflow-y: hidden;
}
.grid-stack-item-backdrop {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    cursor: move;
}
.grid-stack>.grid-stack-item>.ui-delete {
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
    z-index: 90;
    display: none;
}
.grid-stack>.grid-stack-item:hover>.ui-delete {
    display: block;
}
.grid-stack>.grid-stack-item>.ui-resizable-se {
    cursor: nwse-resize;
}
.grid-stack-editable>.grid-stack-item {
    opacity: 0.5;
}
.grid-stack-editable>.grid-stack-item:hover {
    opacity: 1;
}
@media (max-width: 1024px) {
    .grid-stack-item {
        position: relative !important;
        width: auto !important;
        left: 0 !important;
        top: auto !important;
        margin-bottom: 20px;
    }
}
