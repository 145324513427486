.nav-submenu {
    background-color: #fff;
    > li {
        border-bottom: 1px solid #ddd;
        &:last-child {
            border-bottom: none;
        }
        + li {
            margin-top: 0;
        }
        a {
            padding: 15px;
            padding-right: 0;
            &:hover,
            &:focus {
                color: #fff;
                background-color: #ddd;
            }
        }
        &.active {
            padding-bottom: 30px;
        }
        .collapse {
            /*margin-bottom: 30px;*/
            li {
                border-bottom: none;
                &.active {
                    padding-bottom: 0;
                }
                &.active > a {
                    color: #fff;
                    background-color: #A2D7E9;
                }
            }
            a {
                padding: 15px 0 15px 30px;
                &:hover,
                &:focus {
                    color: #fff;
                    background-color: #ddd;
                }
            }
        }
    }
    i {
        text-align: center;
        width: 18px;
        margin-right: 10px;
    }
}

.nav-ellipsis {
    position: absolute;
}

.nav-breadcrumbs {
    padding-left: 48px;
    .breadcrumb {
        margin: 0;
        padding: 10px 0;
        line-height: 26px;
    }
}

@media (max-width: @screen-sm-max) {
    .nav-submenu {
        > li {
            a {
                padding: 10px;
            }
            &.active {
                padding-bottom: 10px;
            }
            .collapse {
                a {
                    padding: 10px 0 10px 20px;
                }
            }
        }
    }
}
