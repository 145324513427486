.login-messages {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.login-wrapper {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 1200px;
}

.login-content {
    flex: 1;
    background: #fff;
    margin: 8px;
    -webkit-box-shadow: 2px 2px 10px 2px #444;
    box-shadow: 2px 2px 10px 2px #444;
}

.login-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;

    img {
        height: 100%;
        width: 100%;
    }
}

.login-logo {
    flex: 1;
    margin: 15px 0;
    text-align: center;

    img {
        height: auto;
        width: 350px;
    }
}

@media (min-width: @screen-md-min) {
    .login-wrapper {
        display: flex;
        margin-top: calc(50vh ~"-" 150px);
    }
    .login-logo {
        margin-top: 89px;
    }
}

.login-content {
    .input-group-addon {
        color: #fff;
        background: @brand-info;
        border-color: @brand-info;
        border-radius: 100%;
        padding: 0 5px;
        font-size: 18px;
        line-height: 32px;
    }
    .form-control {
        border: none;
        box-shadow: none;
        margin-left: 15px;
        outline: none;
        width: calc(100% ~"-" 15px);
    }
}
