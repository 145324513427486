.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    border-radius: 28px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    font-size: 0;
    .transition(background-color .35s);

    &:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 1px;
        bottom: 1px;
        width: 24px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 50%;
        .box-shadow(1px 0 3px rgba(0,0,0,.05));
        .transition(all .15s ease-out);
    }

    &.switch-default {
        border-color: #dbe1e8;
    }
    &.switch-primary {
        border-color: @brand-primary;
    }
    &.switch-info {
        border-color: @brand-info;
    }
    &.switch-success {
        border-color: @brand-success;
    }
    &.switch-warning {
        border-color: @brand-warning;
    }
    &.switch-danger {
        border-color: @brand-danger;
    }
}

.switch.checked {
    background-color: #eee;

    &:after {
        left: 26px;
        border: none;
        -webkit-box-shadow: -2px 0 3px rgba(0,0,0,.1);
        box-shadow: -2px 0 3px rgba(0,0,0,.1);
    }

    &.switch-default {
        background-color: #dbe1e8;
    }
    &.switch-primary {
        background-color: @brand-primary;
    }
    &.switch-info {
        background-color: @brand-info;
    }
    &.switch-success {
        background-color: @brand-success;
    }
    &.switch-warning {
        background-color: @brand-warning;
    }
    &.switch-danger {
        background-color: @brand-danger;
    }
}
