.knob-dial {
    position: relative;
}
.knob-dial > div {
    position: absolute;
    width: 100%;
    z-index: 5;
}
.knob-dial svg {
    transform: rotate(-90deg);
}
