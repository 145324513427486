/* Colors */
@brand-blue: #16749A;
@brand-primary: @brand-blue;
@brand-info: #1f99ca;
@gray-darker: lighten(@gray-base, 20%); // #333
@gray-dark: lighten(@gray-base, 33.5%); // #555
@gray: #87888A;
@gray-light: #ddd;
@gray-lighter: #f6f6f6;
/* Scaffolding */
@text-color: #333;
@link-color: @text-color;
@link-hover-color: @text-color;
/* Typography */
@font-family-sans-serif: 'Open Sans', sans-serif;
@font-size-base: 14px;
@font-size-h1: 36px;
@font-size-h2: 22px;
@font-size-h3: 18px;
@headings-font-weight: 500;
/* Iconography */
/* Components */
@padding-small-vertical: 3px;
@padding-small-horizontal: 8px;
@padding-xs-vertical: 1px;
@padding-xs-horizontal: 3px;
@border-radius-base: 0;
@border-radius-large: 0;
@border-radius-small: 0;
/* Tables */
/* Buttons */
@btn-danger-bg: @brand-blue;
/* Forms */
@input-border-focus: @brand-blue;
/* Dropdowns */
/* Media queries breakpoints */
@screen-md: 1024px;
@screen-lg: 1400px;
/* Grid system */
/* Container sizes */
@container-xs: 100%;
@container-sm: 100%;
@container-md: 100%;
@container-lg: 100%;
/* Navbar */
@navbar-height: 80px;
@navbar-margin-bottom: 20px;
@navbar-border-radius: 0;
@navbar-padding-horizontal: 15px;
@navbar-default-color: #fff;
@navbar-default-bg: @gray-light;
@navbar-default-border: #fff;
@navbar-default-link-color: #fff;
@navbar-default-link-hover-color: @gray;
@navbar-default-link-hover-bg: @gray-light;
@navbar-default-link-active-color: @gray;
@navbar-default-link-active-bg: @gray-lighter;
/* Navs */
@nav-link-hover-bg: transparent;
/* Tabs */
/* Pills */
/* Pagination */
@pagination-hover-bg: #eee;
@pagination-active-bg: @brand-blue;
@pagination-active-border: @brand-blue;
/* Pager */
@pager-border-radius: 0;
/* Jumbotron */
/* Form states and alerts */
/* Tooltips */
@tooltip-color: @text-color;
@tooltip-bg: #fff381;
@tooltip-arrow-color: #fff381;
@tooltip-max-width: 200px;
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
@breadcrumb-padding-vertical: 0;
@breadcrumb-padding-horizontal: 0;
@breadcrumb-color: #333;
@breadcrumb-active-color: #333;
@breadcrumb-separator: ">";
/* Carousel */
/* Close */
/* Code */
/* Type */
