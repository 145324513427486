.agenda-day {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid @gray;
}
.agenda-day-title {
    padding: 0 0 5px;
    color: @gray;
}

.agenda-item {
    position: relative;
    margin-bottom: 5px;
    border-left: 3px solid @btn-info-bg;
    padding: 0 10px;
}
.agenda-item-time {
    position: absolute;
    left: 10px;
    top: 0;
    font-size: 14px;
    padding-top: 2px;
}
.agenda-item-body {
    margin-left: 70px;
}
.agenda-item-title {
    font-weight: bold;
    font-size: 16px;
}

.agenda-item-primary {
    border-color: @text-color;
}
.agenda-item-success {
    border-color: @btn-success-bg;
}
.agenda-item-info {
    border-color: @btn-info-bg;
}
.agenda-item-warning {
    border-color: @btn-warning-bg;
}
.agenda-item-danger {
    border-color: @btn-danger-bg;
}

.agenda .agenda-day:last-child {
    border-bottom: none;
}

.agenda-item-desc {
    padding: 5px 0;
    font-size: 15px;
    color: @gray;
}
