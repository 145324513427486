.mainLayout {
    background-color: @gray-lighter;
    header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1051;
        margin-bottom: @line-height-computed;
        min-height: 60px;
        background-color: @brand-blue;
        color: #fff;
        font-size: 13px;
        .header-brand {
            position: absolute;
            left: 30px;
            top: 0;
            img {
                margin-top: 10px;
            }
        }
        .header-menu {
            display: block;
            z-index: 1051;
            opacity: 1;
            .transition(all .2s ease-in-out);
        }
        .header-mobile {
            display: none;
            text-align: right;
            padding: 10px;
            .btn {
                height: 40px;
                line-height: 40px;
                font-size: 20px;
                padding: 0 10px;
                margin-left: 10px;
                outline: 0;
                color: #fff;
                background: transparent;
                border-color: transparent;//darken(@brand-red, 10%);
                &:hover, &:focus {
                    color: #fff;
                    background: darken(@brand-blue, 10%);
                    border-color: transparent;//darken(@brand-red, 10%);
                }
            }
        }
        .header-backdrop {
            display: none;
            position: fixed;
            background: #ddd;
            width: 100%;
            height: 100%;
            z-index: 1050;
        }
        ul {
            list-style: none;
            float: right;
            margin: 0px;
            height: 60px;
            padding: 0;
            padding-left: 15px;
            > li {
                float: left;
                > a {
                    color: #fff;
                    display: block;
                    padding: 20px;
                    padding-left: 0;
                    &:hover, &:focus {
                        text-decoration: none;
                    }
                }
                i {
                    margin-right: 7px;
                }
            }
        }
        .user-notifications {
            background-color: #2098CA;
            .badge {
                margin-left: 4px;
                background: #f29400;
            }
        }
        .user-info {
            background-color: @brand-blue;
            .badge {
                margin-right: 4px;
                background: #fff;
                color: @brand-blue;
            }
        }
    }
    nav {
        margin-top: 80px;
    }
    .content {
        margin: 0 15px;
    }
    footer {
        margin-top: 30px;
        padding: 0 15px;
        font-size: 10px;
        color: @gray;
    }
    #snippet--flashMessages {
        height: 70px;
        overflow: hidden;
    }
}

@media (max-width: @screen-sm-max) {
    .mainLayout header .header-mobile {
        display: block;
    }
    .mainLayout header .header-brand {
        left: 10px;
    }
    .mainLayout header .header-menu {
        display: none;
        opacity: 0;
        position: fixed;
        top: 60px;
        width: 100%;
        background-color: #ddd;
    }
    .mainLayout header.showed .header-menu {
        display: block;
        opacity: 1;
    }
    .mainLayout header.showed .header-backdrop {
        display: block;
    }
    .mainLayout header ul {
        float: none;
        height: auto;
        padding: 0 !important;
        > li {
            display: block;
            float: none;
            height: auto;
            margin: 0;
            border-bottom: 2px solid #ddd;
            > a {
                padding: 15px 10px;
            }
        }
    }
    .mainLayout nav {
        display: none;
        opacity: 0;
        position: fixed;
        margin: 0;
        z-index: 1051;
        width: 100%;
        height: 100%;
    }
    .mainLayout nav .navbar-collapse {
        padding: 0;
    }
    .mainLayout nav .navbar-nav {
        margin: 0;
        a {
            padding: 15px 10px;
            border-bottom: 2px solid #ddd;
        }
        i {
            width: 1.28571429em;
            text-align: center;
        }
    }
    .mainLayout nav.showed {
        display: block;
        opacity: 1;
    }
    .mainLayout nav.showed #main-menu {
        display: block;
        overflow-y: auto;
    }
    .mainLayout .container {
        margin-top: 60px;
        padding: 0 10px;
    }
    .mainLayout .content {
        margin: 0;
        margin-top: 10px;
    }
    .mainLayout #snippet--flashMessages {
        height: auto;
        overflow: auto;
    }
    .row {
        margin: 0 -10px;
    }
    [class^="col-"] {
        padding: 0 10px;
    }
}

@media (max-width: @screen-sm-max) {
    #tracy-debug-bar {
        display: none !important;
    }
}
