.block {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 25px;
    max-width: 600px;

    &.fullwidth {
        max-width: 100%;
    }

    .block-actions {
        float: right;
        .clearfix();
    }

    .block-heading {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 20px;
        color: @gray;
    }

    .block-content {}

    .block-filter {
        .pull-right;
    }

    .block-breadcrumb {
        min-height: 34px;
        margin-bottom: 20px;
    }
}

.table {
    .table-link {
        font-weight: 700;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.block-form {
    max-width: 600px;
}

// mobile and tablets
@media (max-width: @screen-sm-max) {
    .block {
        .block-filter {
            float: left !important;
            width: 100%;
        }
    }
    .form-inline > * {
        width: 100% !important;
        margin-bottom: 10px !important;
    }
}
